import React from 'react'
import ReactDOM from 'react-dom/client'
import {CssVarsProvider} from '@mui/joy/styles'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import Home from './pages/Home'
import {Container, Grid, Sheet, Typography} from '@mui/joy'
import ModeToggle from './ModeToggle'
import './index.css'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>,
  }
])

const root = ReactDOM.createRoot(document.getElementById('website-container'))
root.render(
  <CssVarsProvider defaultMode='system'>
    <Sheet sx={{minHeight: '100vh'}}>
      <Grid container py={1} px={3}>
        <Grid item xs={11} display='flex' sx={{placeItems: 'center'}}>
          <Typography level='h1' fontWeight='bold'>
            Jan Dominik Lindner
          </Typography>
        </Grid>
        <Grid item xs={1} display='flex' sx={{placeContent: 'end'}}>
          <ModeToggle/>
        </Grid>
      </Grid>
      <Container maxWidth='md' sx={{mt: 12}}>
        <RouterProvider router={router}/>
      </Container>
    </Sheet>
  </CssVarsProvider>,
)

import React from 'react'
import {useColorScheme} from '@mui/joy/styles'
import {Box, Card, CardCover, Grid, Link, ListDivider, Typography} from '@mui/joy'

const socials = [
  {
    name: 'Twitter',
    url: 'https://twitter.com/HSGMiner',
    icon: '/images/twitter.svg',
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/_jan_lindner_/',
    icon: '/images/instagram.png',
  },
  {
    name: 'GitHub',
    url: 'https://github.com/janLindner',
    icon: '/images/github.svg',
    iconDark: '/images/github_dark_mode.svg',
  },
  {
    name: 'Discord',
    url: 'https://discordapp.com/users/HSGMiner#9890',
    icon: '/images/discord.svg',
  },
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/in/jan-lindner-813094223/',
    icon: '/images/linkedin.png',
  },
  {
    name: 'Stackoverflow',
    url: 'https://stackoverflow.com/users/20070175/jan-lindner',
    icon: '/images/stackoverflow.svg',
  },
]

const Home = () => {
  const {mode} = useColorScheme()

  return (
    <Box>
      <Card sx={{minHeight: '300px', mb: 3}}>
        <CardCover>
          <img src="/images/profile.jpg" alt="Jan Lindner"/>
        </CardCover>
      </Card>
      <Grid container spacing={3}>
        {socials.map((social, index) => (
          <Grid key={index} item md={3} xs={6}>
            <Card
              variant="outlined"
              sx={{
                '&:hover': {boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder'},
              }}
            >
              <Box display="flex" sx={{placeContent: 'center'}}>
                <img height="80px" src={(mode === 'dark' && social.iconDark) ? social.iconDark : social.icon}
                     alt={`${social.name} Icon`}/>
              </Box>

              <ListDivider sx={{mt: 3, mb: 1}}/>
              <Link overlay underline="none" href={social.url} target="_blank" display="flex"
                    sx={{placeContent: 'center'}}>
                <Typography fontWeight="bold">
                  {social.name}
                </Typography>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Home

import React from 'react'
import {Switch} from '@mui/joy'
import {useColorScheme} from '@mui/joy/styles'
import {DarkMode, LightMode} from '@mui/icons-material'

const ModeToggle = () => {
  const {mode, setMode} = useColorScheme()

  return (
    <Switch
      variant='solid'
      onChange={() => setMode(mode === 'dark' ? 'light' : 'dark')}
      slotProps={{
        thumb: {
          children: (
            <>
              {mode === 'light' && <LightMode/>}
              {mode === 'dark' && <DarkMode/>}
            </>
          )
        }
      }}
      sx={{
        '--Switch-thumb-size': '27px',
        '--Switch-track-width': '64px',
        '--Switch-track-height': '32px',
      }}
    />
  )
}

export default ModeToggle
